<template>
    <mhospital style="margin-bottom:10px" ref="main0" title="报告" table_name="bioland_kkbp" table_show_header
               :is_search="is_search">
        <!-- 弹出编辑模态窗口 -->
        <template #writeForm="{writeForm}">
            <el-row>
                <el-form-item prop="note" label="备注">
                    <el-input clearable type="textarea" :rows="4" style="width:300px" v-model="writeForm.note"
                              placeholder=""/>
                </el-form-item>
            </el-row>
        </template>
        <!-- 搜索框 -->
        <template #searchForm="{searchForm}">
            <el-form-item prop="uid" label="患者">
                <el-select v-model="searchForm.uid" placeholder="请选择" clearable filterable size="small"
                           @change="search_btn('main0')">
                    <el-option value="" label="全部"/>
                    <el-option v-for="item in user_opt" :key="item.id" :label="item.name+' '+item.mobile"
                               :value="item.id"/>
                </el-select>
            </el-form-item>
            <el-form-item prop="client_id" label="设备">
                <el-select v-model="searchForm.client_id" placeholder="请选择" clearable filterable size="small"
                           @change="search_btn('main0')">
                    <el-option value="" label="全部"/>
                    <el-option v-for="item in device_opt" :key="item.id" :label="item.note+' '+item.imei"
                               :value="item.imei"/>
                </el-select>
            </el-form-item>
            <el-form-item prop="hospital_id" label="社区医院" v-if="hrole==23||hrole==24">
                <el-select v-model="searchForm.hospital_id" placeholder="请选择" clearable filterable size="small"
                           @change="search_btn('main0')">
                    <el-option value="" label="全部"/>
                    <el-option v-for="item in hospital_opt" :key="item.id" :label="item.name"
                               :value="item.id"/>
                </el-select>
            </el-form-item>
            <el-form-item label="报告状态" prop="is_24">
                <el-radio-group v-model="searchForm.is_24" @change="search_btn('main0')">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">未生成</el-radio-button>
                    <el-radio-button label="1">已生成</el-radio-button>
                </el-radio-group>
            </el-form-item>
        </template>
        <!-- 列表 -->
        <template #table>
            <el-table-column v-if="hrole=='1'" label="社区医院" width="180" prop="hospital.name" align="center"/>
            <el-table-column label="报告编号" width="80" prop="id" align="center"/>
            <el-table-column label="设备序列号" prop="client_id" width="160" align="center"/>
            <el-table-column label="设备编号" align="center" width="80">
                <template slot-scope="scope">
                    <span v-if="scope.row.device">
                        {{scope.row.device.note}}
                    </span>

                </template>
            </el-table-column>
            <el-table-column label="姓名" align="center" width="80">
                <template slot-scope="scope">
					<span v-if="scope.row.user&&scope.row.user.id>0">
						{{scope.row.user.name}}
					</span>
                    <span v-else>
						<el-tag type="danger" size="mini">未绑定</el-tag>
					</span>
                </template>
            </el-table-column>
            <el-table-column label="user_id" prop="user.id" width="80" align="center"/>
            <el-table-column v-if="hrole==23||hrole==24" label="社区医院" prop="hospital.name" align="center"/>
            <el-table-column label="手机号" align="center" width="120">
                <template slot-scope="scope">
					<span v-if="scope.row.user&&scope.row.user.id>0">
						{{scope.row.user.mobile}}
					</span>
                    <span v-else>
						-
					</span>
                </template>
            </el-table-column>
            <el-table-column label="报告状态" align="center" width="80">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.is_24==1" size="mini">已生成</el-tag>
                    <el-tag type="danger" v-else size="mini">未生成</el-tag>
                </template>
            </el-table-column>
            "
            <el-table-column label="测量开始时间" align="center" width="180">
                <template slot-scope="scope">
                    {{scope.row.DT}}
                </template>
            </el-table-column>
            <el-table-column label="备注" prop="note" align="left"/>
            <el-table-column label="操作" width="280" align="center">
                <template slot-scope="scope">
                    <el-link v-if="scope.row.is_24==1" style="margin-right:10px" icon="el-icon-view" type="primary"
                             @click="look_btn('main0', scope.row.id)">查看
                    </el-link>
                    <el-link v-else style="margin-right:10px" icon="el-icon-view" type="primary"
                             @click="look_btn('main0', scope.row.id)">数据
                    </el-link>
                    <el-link style="margin-right:10px" icon="el-icon-download" type="primary"
                             @click="down_btn('main0', scope.row.id)">下载
                    </el-link>
                    <el-link style="margin-right:10px" icon="el-icon-edit" type="primary"
                             @click="edit_btn('main0', scope.row.id)">备注
                    </el-link>
                    <el-link style="margin-right:10px" icon="el-icon-delete" type="primary"
                             @click="del_btn('main0', scope.row.id)">删除
                    </el-link>
                </template>
            </el-table-column>
        </template>
    </mhospital>
</template>
<script>
    import mhospital from "../../components/mhospital2.vue";
    import request from "../../plugins/axios";

    export default {
        data() {
            return {
                user_opt: [],
                device_opt: [],
                url: "",
                is_search: false,
                hospital_opt: [],
                hrole: ''
            };
        },
        components: {
            mhospital,
        },
        mounted() {
            request({
                url: "/hospital/Read/user_opt",
                data: {},
            }).then((ret) => {
                if (ret.code == 1) {
                    this.user_opt = ret.data;

                }
            });
            request({
                url: "/hospital/Read/device_opt",
                data: {},
            }).then((ret) => {
                if (ret.code == 1) {
                    this.device_opt = ret.data;
                }
            });

            request({
                url: "/hospital/Read/jk_hospital_opt",
                data: {hp: 2000},
            }).then((ret) => {
                if (ret.code == 1) {
                    this.hospital_opt = ret.data;
                }
            });


            if (this.$route.params.uid && this.$route.params.uid > 0) {
                this.$refs["main0"].set_searchForm("uid", this.$route.params.uid);
            } else if (this.$route.params.imei && this.$route.params.imei > 0) {
                this.$refs["main0"].set_searchForm("client_id", this.$route.params.imei);
            } else {
                this.$refs["main0"].onSearch();
            }


            this.hrole = localStorage.getItem('hrole')
        },
        methods: {
            search_btn(ref_name) {
                this.$refs[ref_name].onSearch();
            },
            del_btn(ref_name, id) {
                this.$refs[ref_name].onDelete(id);
            },
            edit_btn(ref_name, id) {
                this.$refs[ref_name].showWriteForm(id);
            },
            look_btn(ref_name, id) {
                request({
                    url: "/hospital/Read/make_docx_v2",
                    data: {
                        id,
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        window.open(
                            "https://view.officeapps.live.com/op/view.aspx?src=" +
                            ret.data +
                            "&wdOrigin=BROWSELINK"
                        );
                    }
                });
            },
            down_btn(ref_name, id) {
                request({
                    url: "/hospital/Read/make_docx_v2",
                    data: {
                        id,
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        window.open(ret.data);
                    }
                });
            },
            addsub_btn(ref_name, send_id) {
                this.$refs[ref_name].onAddsub({
                    id: 0,
                    buy_id: send_id,
                    goods_type: "",
                    goods_num: "",
                    client_id: "",
                    note: "",
                });
            },
            deletesub_btn(ref_name, index) {
                this.$refs[ref_name].onDeletesub(index);
            },
            go_tab(activeName, oldActiveName) {
                // console.log(activeName);
                // if (activeName == "1") {
                // this.$refs["main2"].writeForm.role = 4;
                // this.$refs["main2"].searchForm.role = 4;
                // } else if (activeName == "0") {
                // this.$refs["main0"].writeForm.role = "";
                // this.$refs["main0"].searchForm.role = "";
                // }
            },
            padNumber(num, fill) {
                let len = ("" + num).length;
                if (fill > len) {
                    // 新建一个空数组，长度为所缺位数+1，利用join(0)，得到一个000...的字符串
                    num = Array(fill - len + 1 || 0).join(0) + num;
                }
                return num;
            },
        },
    };
</script>
